import React from 'react'
import './casinosList.css'

const CasinosList = ({ title, casinosList }) => {


    return (
        <div>
            {title && <h2 className="list-title">{title}</h2>}
            <div className="casinos-list">
                {casinosList?.length && casinosList.map((casinoItem) => {
                    return (
                        <div className="item-wrapper" key={casinoItem?.casinoName}>
                            <div class="casino-top">
                                <div className="logo-wrapper">
                                    <a href={casinoItem?.affiliateLinkki} target="_blank" rel="noopener noreferrer">
                                        <img src={casinoItem?.casinoLogo?.mediaItemUrl} alt={casinoItem?.casinoName} />
                                        <p>{casinoItem?.casinoName}</p>
                                    </a>
                                </div>
                                <div className="casino-rate">
                                    {Array(casinoItem?.arvostelu).fill(<span>&#9733;</span>)}
                                </div>
                                <div className="context-box content-info">
                                    {casinoItem?.bonukset && (
                                        <div className="offer-item">
                                            <i>Bonus:</i>
                                            <div className="offer">
                                                {casinoItem?.bonukset}
                                            </div>
                                        </div>
                                    )}
                                    {casinoItem?.ilmaiskierrokset && (
                                        <div className="offer-item">
                                            <i>Freispiele:</i>
                                            <div className="offer">
                                                {casinoItem?.ilmaiskierrokset}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="context-box context-second">
                                    <div className="content-text">
                                        {casinoItem?.teksti}
                                    </div>
                                </div>
                                <div className="content-button">
                                    <div className="contentBtn">
                                        <a href={casinoItem?.affiliateLinkki} className="btn" target="_blank" rel="noopener noreferrer">Jetzt Spielen</a>
                                    </div>
                                    <div>
                                        {casinoItem?.termsLink && <a href={casinoItem?.affiliateLinkki} className="terms-link" target="_blank" rel="noopener noreferrer">Säännöt ja ehdot</a>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CasinosList
