import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import BodyContent from "../layouts/bodyContent/BodyContent"
import CasinosList from "../layouts/casinosList/CasinosList"

const SitePage = props => {
  const { pageBuilder } = props?.data?.page;
  const SEOdata = pageBuilder.layouts[0]
  return (
    <Layout
      bodyClass={`page-template-default page wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo
        title={SEOdata?.seoTitle}
        description={SEOdata?.seoDescription}
      />
      <div>
        {pageBuilder?.layouts.map((layout => {
          const type = layout.fieldGroupName
          if (type === "Page_Pagebuilder_Layouts_BodyText") {
            return (
              <BodyContent data={layout?.bodyText} />
            )
          } else if (type === "Page_Pagebuilder_Layouts_CasinosList") {
            return (
              <CasinosList
                casinosList={layout?.listCasinos}
                title={layout?.listTitle}
              />
            )
          }
        }))}
      </div>
    </Layout>
  )
}

export default SitePage

export const query = graphql`
query FrontPage($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_CasinosList {
            fieldGroupName
            listTitle
            listCasinos {
              affiliateLinkki
              arvostelu
              bonukset
              casinoName
              fieldGroupName
              ilmaiskierrokset
              teksti
              termsLink
              casinoLogo {
                mediaItemUrl
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_BodyText {
            bodyText
            fieldGroupName
          }
          ... on WpPage_Pagebuilder_Layouts_SeoComponent {
            fieldGroupName
            seoDescription
            seoTitle
          }
        }
      }
    }
  }
`
