import React from 'react'
import './bodyContent.css'

const BodyContent = ({ data }) => {

    return (
        <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: data }}
        />
    )
}

export default BodyContent
